import React, { useEffect, useState } from 'react';
import axios from 'axios';

const InventoryPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState(null); // Track which row is expanded
    const API_URL = process.env.REACT_APP_API_URL;
    useEffect(() => {
      const fetchOrders = async () => {
        try {
          const response = await axios.get(`${API_URL}/inventory/sanmar`);
          setOrders(response.data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchOrders();
    }, []);
  
    const toggleExpandRow = (index) => {
      setExpandedRow(expandedRow === index ? null : index);
    };
  
    if (loading) {
      return <h1>Loading...</h1>;
    }
  
    return (
      <div className="container" style={{ marginLeft: 250, paddingTop: 20,marginBottom:70, fontFamily: 'Arial, sans-serif' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>SanMar Orders</h1>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'left' }}>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Purchase Order Number</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Sales Order Number</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Status</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Expected Ship Date</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Last Updated</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <React.Fragment key={order.purchaseOrderNumber}>
                {/* Main Row */}
                <tr>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{order.purchaseOrderNumber}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                    {order.orderDetails[0]?.salesOrderNumber || 'N/A'}
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                    {order.orderDetails[0]?.status || 'N/A'}
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                    {order.orderDetails[0]?.expectedShipDate
                      ? new Date(order.orderDetails[0]?.expectedShipDate).toLocaleDateString()
                      : 'N/A'}
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                    {order.orderDetails[0]?.lastUpdated
                      ? new Date(order.orderDetails[0]?.lastUpdated).toLocaleString()
                      : 'N/A'}
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'center' }}>
                    <button
                      onClick={() => toggleExpandRow(index)}
                      style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      {expandedRow === index ? 'Hide Details' : 'View Details'}
                    </button>
                  </td>
                </tr>
  
                {/* Expandable Row */}
                {expandedRow === index && (
                  <tr>
                    <td colSpan="6" style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f9f9f9' }}>
                      <h4>Product Details</h4>
                      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                        <thead>
                          <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'left' }}>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Product ID</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Part ID</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Order Line Number</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Quantity Ordered</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Quantity Shipped</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.orderDetails[0]?.products.map((product, pIndex) => (
                            <tr key={pIndex}>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.productId}</td>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.partId}</td>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.salesOrderLineNumber}</td>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.quantityOrdered}</td>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.quantityShipped}</td>
                              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{product.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default InventoryPage;
