import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function ScanHandler() {
  const [scannedData, setScannedData] = useState(""); // Stores scanned data
  const [error, setError] = useState(null); // Stores errors for invalid scans
  const [isLoading, setIsLoading] = useState(false); // Loading state for fetching
  const inputRef = useRef(null); // Ref for focusing the input field
  const API_URL = process.env.REACT_APP_API_URL; // Environment variable for API URL
  const navigate = useNavigate();

  // Ensure the input field is always focused
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Handle the scanned input with debounce
  const handleScan = async (event) => {
    const data = event.target.value.trim(); // Get the scanned data
    setScannedData(data); // Update state
    setError(null); // Clear any previous errors

    try {
      if (!data) {
        throw new Error("No data scanned. Please scan a valid label.");
      }

      setIsLoading(true); // Start loading state

      const orderId = data; // Use scanned data as the OrderID

      // Fetch the job type from the database
      const jobType = await fetchJobType(orderId);

      // Redirect to the respective job type page
      redirectToJobTypePage(orderId, jobType);

      setScannedData(""); // Clear input after success
      setError(null); // Clear any errors
    } catch (err) {
      setError(err.message);
      console.error("Error:", err.message);
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  // Fetch the job type for a given OrderID from the database
  const fetchJobType = async (orderId) => {
    try {
      const response = await fetch(`${API_URL}/getJobType?orderId=${orderId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch job type. Server responded with ${response.status}`);
      }
      const data = await response.json();

      if (!data.jobType) {
        throw new Error("Job type not found for the given OrderID.");
      }

      return data.jobType.toUpperCase(); // Convert to uppercase for consistency
    } catch (error) {
      throw new Error(`Error fetching job type: ${error.message}`);
    }
  };

  // Redirect to the respective job type page
  const redirectToJobTypePage = (orderNumber, jobType) => {
    const jobTypeUrls = {
      EMBROIDERY: "/embroidory",
      DTG: "/dtg",
      SCREENPRINTING: "/screenPrinting",
    };

    const baseUrl = jobTypeUrls[jobType];

    if (baseUrl) {
      // Redirect to the page with the search query
      navigate(`${baseUrl}?search=${encodeURIComponent(orderNumber)}`);
    } else {
      throw new Error(`Invalid Job Type: ${jobType}.`);
    }
  };

  // Keyboard shortcut: Reset scanner
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setScannedData(""); // Clear input
        setError(null); // Clear errors
        if (inputRef.current) {
          inputRef.current.focus(); // Refocus the input field
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      className="container"
      style={{
        marginLeft: 250,
        paddingTop: 20,
        marginBottom: 70,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>Scan Your Label</h1>
      <input
        type="text"
        ref={inputRef} // Keep the input field focused
        value={scannedData}
        onChange={handleScan} // Triggered when scanning
        placeholder="Scan label..."
        style={{
          width: "300px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
        autoFocus
      />
      {isLoading && <p>Loading...</p>} {/* Show loading spinner */}
      {error && (
        <p style={{ color: "red", marginTop: "10px" }}>
          {error} <br />
          Press <b>Escape</b> to reset.
        </p>
      )}{" "}
      {/* Display errors */}
    </div>
  );
}

export default ScanHandler;
