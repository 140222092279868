import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const Entry = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [password_hash, setPassword_hash] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); // For Forgot Password modal input
  const [showModal, setShowModal] = useState(false); // To toggle Forgot Password modal
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for forgot password
  const navigate = useNavigate();

  // Handle login form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = { email, password_hash };

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const result = await response.json();

      if (response.ok) {
        const user = { email: email, name: result.name, token: result.token };
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/embroidory");
      } else {
        alert(result.message);
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An error occurred during login.");
    }
  };

  // Handle Forgot Password request
  const handleForgotPassword = async () => {
    if (!forgotPasswordEmail) {
      alert("Please enter your email address.");
      return;
    }

    setIsSubmitting(true); // Start loading state
    try {
      const response = await fetch(`${API_URL}/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: forgotPasswordEmail }),
      });
      const result = await response.json();

      if (response.ok) {
        alert("A temporary password has been sent to your email. Use it to log in and change your password.");
        setShowModal(false); // Close modal
        setForgotPasswordEmail(""); // Clear input
      } else {
        alert(result.message);
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An error occurred while sending the temporary password.");
    } finally {
      setIsSubmitting(false); // End loading state
    }
  };

  return (
    <div className="login-page">
      <div className="offer-section">
        <h1>PRINT YOUR PASSION</h1>
        <h2>Custom tees that speak volumes</h2>
        <h3>Wear what you love ❤️</h3>
      </div>

      <div className="form-section">
        <h2>Login to Your Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              className="input-field"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password_hash}
              onChange={(e) => setPassword_hash(e.target.value)}
              required
              placeholder="Enter your password"
              className="input-field"
            />
          </div>
          <button type="submit" className="submit-button">
            Login
          </button>
        </form>

        <button
          type="button"
          className="forgot-password-button"
          onClick={() => setShowModal(true)}
        >
          Forgot Password?
        </button>
      </div>

      {/* Forgot Password Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Enter your email address</label>
            <input
              type="email"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              placeholder="Enter your email"
              className="input-field"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleForgotPassword}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send Temporary Password"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Entry;
