import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { BsDownload } from 'react-icons/bs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import Barcode from 'react-barcode';
// const printer = require('printer');
import { useRef } from 'react';

import bwipjs from 'bwip-js';





const Pullsheet = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [orders, setOrders] = useState([]);
  const [openOrder, setOpenOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [orderId, setOrderId] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [sortByPO,setSortByPO] =useState(false)
  const barcodeRef = useRef(null);
  const canvasRef = useRef(null);
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const [labelOrder, setLabelOrder] = useState(null);
  const [showLabelModal, setShowLabelModal] = useState(false);

  // const generateZPLLabel = (order) => {
  //   return `
  // ^XA
  // ^FO50,50^A0N,30,30^FDOrder Number: ${order.orderNumber}^FS
  // ^FO50,90^A0N,30,30^FDCustomer Name: ${order.clientName}^FS
  // ^FO50,130^A0N,30,30^FDShipping Address: ${order.shippingAddress}^FS
  // ^FO50,170^A0N,30,30^FDGarment Details: ${order.garmentDetails || 'No Garment Details'}^FS
  // ^FO50,210^BY2,2,50^BCN,100,Y,N^FD${order.orderNumber}^FS
  // ^XZ
  //   `;
  // };

  // const printLabel = (order) => {
  //   showLabelModal(true)
  //   const orderZPL = generateZPLLabel(order);
  //   handleprintLabel(orderZPL);
  // };
  // const handleprintLabel = (zpl) => {
  //   printer.printDirect({
  //     data: zpl,
  //     type: 'RAW', // Zebra printers expect RAW data
  //     success: (jobId) => {
  //       console.log(`Label printed successfully with Job ID: ${jobId}`);
  //     },
  //     error: (err) => {
  //       console.error('Failed to print label:', err);
  //     },
  //   });
  // };


  
  
  const renderBarcodeToCanvas = (orderNumber) => {
    const canvas = canvasRef.current;
  
    if (!canvas) {
      console.error('Canvas element not found.');
      return;
    }
  
    // Clear the canvas before rendering the barcode
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    // Render the barcode
    bwipjs.toCanvas(canvas, {
      bcid: 'code128',       // Barcode type
      text: orderNumber,     // Text to encode
      scale: 3,              // Scaling factor
      height: 15,            // Bar height in mm
      includetext: true,     // Include human-readable text
      textxalign: 'center',  // Align text to the center
    });
  };
  
  const generateLabelPDF = (order) => {
    const doc = new jsPDF({
      orientation: 'lanscape',
      unit: 'in', // Use inches
      format: [2,1 ], // 4x6 inches label size
    });
    doc.setFontSize(12); // Set smaller font size for better spacing
    const labelWidth = 2; // Total width of the label
    const labelHeight = 1; // Total height of the label
  
    // Add Customer Name - horizontally centered
    const textWidth = doc.getTextWidth(`Customer Name: ${order.clientName}`);
    const textX = (labelWidth - textWidth) / 2; // Center the text
    const textY = 0.3; // Position closer to the top
    doc.text(` ${order.clientName}`, textX, textY);
  
    
    const canvas = canvasRef.current;
    if (canvas) {
      const barcodeImage = canvas.toDataURL('image/png');
      doc.addImage(barcodeImage, 'PNG', 0.25, 0.5, 1.5, 0.4); // Barcode dimensions
    } else {
      console.error('Canvas is empty. Barcode not rendered.');
    }
  
    // Open the PDF in a new tab
    const pdfUrl = doc.output('bloburl');
    window.open(pdfUrl, '_blank');
  };
  
  const downloadAllLabels = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [2, 1],
    });
  
    let yPosition = 0; // Start position for each label
  
    orders.forEach((order, index) => {
      // Add Customer Name
      doc.setFontSize(8);
      const textWidth = doc.getTextWidth(`Customer Name: ${order.clientName}`);
      const textX = (2 - textWidth) / 2; // Center the text
      doc.text(`Customer Name: ${order.clientName}`, textX, 0.3);
  
      // Render Barcode to Canvas
      renderBarcodeToCanvas(order.orderNumber);
  
      // Add Barcode to the PDF
      const canvas = canvasRef.current;
      if (canvas) {
        const barcodeImage = canvas.toDataURL('image/png');
        doc.addImage(barcodeImage, 'PNG', 0.25, 0.5, 1.5, 0.4); // Barcode dimensions
      } else {
        console.error('Canvas is empty. Barcode not rendered.');
      }
  
      // Add a new page for the next label unless it's the last order
      if (index < orders.length - 1) {
        doc.addPage([2, 1], 'landscape'); // Add a new 2x1-inch page
      }
    });
  
    // Save the PDF
    doc.save(`All_Labels_${moment().format('YYYY-MM-DD')}.pdf`);
  };
  
  
  


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${API_URL}/pullsheetList?search=${search}`, {
          params: {
            fromDate: fromDate,
            toDate: toDate,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [fromDate, toDate, search]);

  useEffect(() => {
    console.log('Current Orders:', orders); // Log the orders list on each update
  }, [orders]); // This will trigger every time the orders state changes
  

  const handleOrderClick = (orderNumber, order_Id) => {
    setOpenOrder(openOrder === orderNumber ? null : orderNumber);
    setOrderId(order_Id);
  };

  const handleDateChange = (e) => {
    if (e.target.name === 'fromDate') {
      setFromDate(e.target.value);
    } else {
      setToDate(e.target.value);
    }
  };

  // Function to reorder items after dragging
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Handle drag end
  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
 
  
  const onDragEnd = debounce((result) => {
    const { source, destination } = result;
    console.log(result)
    if (!destination) return;
  
    const reorderedOrders = reorder(orders, source.index, destination.index);
    setOrders(reorderedOrders);
  }, 300);

  const downloadEmbroideryPDF = () => {
    const embroideryOrders = orders.filter(order => order.jobType === 'EMBROIDERY');
    generatePDF(embroideryOrders, 'PullSheetEmbroideryOrders');
  };
  
  const downloadDTGPDF = () => {
    const dtgOrders = orders.filter(order => order.jobType === 'DTG');
    generatePDF(dtgOrders, 'PullSheetDTGOrders');
  };
  
  const generatePDF = (data, title) => {
    const tableData = data ? data.map(order => [
      order.id,
      order.orderNumber,
      order.clientName,
      order.garmentPO,
      order.garmentDetails ? 
      order.garmentDetails.split('\n').join(', ') : // Convert to a comma-separated string
      'No Garment details',
      order.jobType,
    ]) : [];
  
    const tableColumns = ['ID', 'Order Number', 'Client Name', 'Garment PO', 'Garment Details', 'JobType'];
  
    const doc = new jsPDF('landscape');
    doc.text(title, 14, 22);
    doc.autoTable({
      head: [tableColumns],
      body: tableData,
      startY: 30,
      theme: 'grid',
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133], textColor: 255 },
    });
    doc.save(`${title ? title.replace(/ /g, '_') : 'PullSheetOrders'}_${moment().format('YYYY-MM-DD')}.pdf`);

  };
  
  
function cleanFileName(url) {
  // Decode URI components to handle encoded characters like %20 for space
  let decodedUrl = decodeURIComponent(url);
  
  // Extract the filename from the URL if necessary
  let fileName = decodedUrl.split('/').pop(); // Gets the last part after the last slash
  
  // Optional: Remove any unwanted parts from the filename, such as timestamps or identifiers
  // For example, if filenames contain a pattern like '1730410751349-', you might want to remove it:
  fileName = fileName.replace(/^\d+-/, ''); // Removes leading digits followed by a dash
  
  // More cleaning can be applied here depending on the specific filename patterns you have
  
  return fileName;
}
  const toggleSortByPO = () => {
    setSortByPO(currentState => {
        const newState = !currentState;
        
        // Fetch sorted data from the backend
        axios.get(`${API_URL}/orders/sorted?sortByPO=${newState}`)
            .then(response => {
                setOrders(response.data);
            })
            .catch(error => {
                console.error('Error fetching sorted orders:', error);
            });

        return newState;
    });
};
  return (
    <div className="container" style={{ marginLeft: 250, paddingTop: 20, marginBottom: 70 }}>
      <h2>Pullsheet</h2>

      <div className="row">
        <div className="col-md-3">
          <label>From Date:</label>
          <input
            type="date"
            className="form-control"
            name="fromDate"
            value={fromDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="col-md-3">
          <label>To Date:</label>
          <input
            type="date"
            className="form-control"
            name="toDate"
            value={toDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="col-md-4">
          <label>Search Orders:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Search orders... 🔍"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ backgroundColor: 'transparent' }}>
              <BsDownload style={{ marginRight: '5px' }} /> Download
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>generatePDF(orders,'PullSheetOrders')}>Download All Orders</Dropdown.Item>
              <Dropdown.Item onClick={downloadEmbroideryPDF}>Download Embroidery</Dropdown.Item>
              <Dropdown.Item onClick={downloadDTGPDF}>Download DTG</Dropdown.Item>
              <Dropdown.Item onClick={downloadAllLabels}>Download All Labels</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {orders.length > 0 ? (
      <DragDropContext onDragEnd={onDragEnd}>
        
            <div>
              <table className="table table-striped table-hover">
                <thead className="thead-dark table-header">
                  <tr>
                    <th scope="col">Order Number</th>
                    <th scope="col">Client Name</th>
                    <th scope="col">Client Phone</th>
                    <th scope="col">Client Gmail</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Order Method</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Garment PO
                    <i
                      className={`bi bi-sort-${sortByPO ? 'down' : 'up'} sort-icon`}
                      onClick={toggleSortByPO}
                    ></i>
                    </th>
                    <th scope="col">Tracking Number</th>
                    <th scope="col">print lable</th>
                  </tr>
                </thead>
                <Droppable droppableId="orders">
               {(provided) => (
                <tbody  ref={provided.innerRef} {...provided.droppableProps}>
                  {orders.map((order, index) => (
                    <Draggable key={order.id.toString()} draggableId={order.id.toString()} index={index}>
                      {(provided) => (
                        <React.Fragment>
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            
                          >
                            {console.log (order.id, index) }
                            <td className="order-cell"
                              onClick={() => handleOrderClick(order.orderNumber,order.id)}
                            >
                                {order.orderNumber}
                            </td>
                            <td>{order.clientName}</td>
                            <td>{order.clientPhone}</td>
                            <td>{order.clientgmail}</td>
                            <td>{order.orderStatus}</td>
                            <td>{order.orderMethod}</td>
                            <td>{order.jobType}</td>
                            <td>{new Date(order.dueDate).toLocaleDateString('en-US')}</td>
                            <td>{order.garmentPO}</td>
                            <td>{order.trackingLabel}</td>
                            <td>
                            <canvas ref={canvasRef} width="500" height="200" style={{ display: 'none' }} />

                              <Button
                                variant="primary"
                                onClick={() => {
                                  renderBarcodeToCanvas(order.orderNumber, canvasRef.current);
                                  generateLabelPDF(order, canvasRef.current);
                                }}
                              >
                                Get Label
                              </Button>
                              </td>

                          </tr>
                          <tr>
                            <td colSpan="12">
                              <Collapse in={openOrder === order.orderNumber}>
                              <div>
                                  <p><strong>Shipping Address:</strong> {order.shippingAddress}</p>
                                  <p><strong>Garment Details:</strong> {order.garmentDetails}</p>
                                  <p><strong>Team:</strong> {order.team}</p>
                                  <p><strong>Notes:</strong> <div style={{ paddingLeft: '20px' }}>
                              {order.notes ? (
                                <ul>
                                  {order.notes.split('\n').map((detail, index) => (
                                    <li key={index}>{detail}</li> // Display each detail as a list item
                                  ))}
                                </ul>
                              ) : (
                                <p>No notes</p>
                              )}
                          </div></p>
                                  
                                  <p><strong>Files Uploaded:</strong></p>
                                  <ul style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
                              {order.files && order.files.length > 0 ? (
                                order.files.filter((file) => file.fileUrl)
                                .map((file, idx) => {
                                  const fileUrl = String(file.fileUrl); // Convert fileUrl to a string
                                  const fileName = cleanFileName(fileUrl); // Clean the filename for display

                                  return (
                                    <li key={idx} style={{
                                      margin: '5px',
                                      width: 'calc(100% / 7 - 10px)', 
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                      {/* Display different content based on file type */}
                                      {fileUrl.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                                        <>
                                          <img
                                            src={fileUrl}
                                            alt={`file-${idx}`}
                                            style={{ width: '100%', height: 'auto', maxWidth: '100px', marginBottom: '5px' }}
                                            onClick={() => handleImageClick(fileUrl)}
                                          />
                                          <div>
                                            <a href={fileUrl} download={fileName}>
                                              {fileName} {/* Display the cleaned filename */}
                                            </a>
                                            
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div style={{
                                              display: 'flex', 
                                              alignItems: 'center', 
                                              justifyContent: 'center', 
                                              width: '100px', 
                                              height: '100px', 
                                              border: '1px solid #ddd', 
                                              borderRadius: '5px', 
                                              backgroundColor: '#f8f9fa'
                                            }}
                                          >
                                            <i className="bi bi-file-earmark-text" style={{ fontSize: '24px' }}></i>
                                          </div>
                                          <div>
                                            <a href={fileUrl} download={fileName}>
                                              {fileName} {/* Display the cleaned filename */}
                                            </a>
                                            
                                          </div>
                                        </>
                                      )}
                                    </li>
                                  );
                                })
                              ) : (
                                <li style={{ width: '100%', textAlign: 'center' }}>No files uploaded.</li>
                              )}
                            </ul>


                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  
                  {provided.placeholder}
                  
                  
                </tbody>
              )}
              </Droppable>
              </table>
            </div>
        
        
      </DragDropContext>
      ):
      <h1>No orders available.
      </h1>}

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showLabelModal} onHide={() => setShowLabelModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Label</Modal.Title>
        </Modal.Header>
        <Modal.Body className="printable-modal">
          {labelOrder && (
            <div style={{ textAlign: 'center' }}>
              <h5>Order Number: {labelOrder.orderNumber}</h5>
              <p><strong>Customer Name:</strong> {labelOrder.clientName}</p>
              <p><strong>Shipping Address:</strong> {labelOrder.shippingAddress}</p>
              <p><strong>Garment Details:</strong> {labelOrder.garmentDetails || 'No Garment Details'}</p>
              <Barcode value={labelOrder.orderNumber} ref={barcodeRef} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLabelModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={printLabel}>
            Print Label
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default Pullsheet;
